<template>
  <div class="confirm-success df fdc alc">
    <icon-svg class="main-color success-svg" icon-class="yi_icon_xuanze"/>
    <div>确认收货成功</div>
    <footer @click.stop="toOrderList">
      <MyButton title="好的" :is_disabled=false></MyButton>
    </footer>
  </div>
</template>

<script>
import MyButton from '@/components/button/button'
export default {
  name: "ConfirmSuccess",
  components: {
    MyButton
  },
  methods:{
    toOrderList(){
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped lang="less">
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";
.confirm-success {
  .wh(100%, 100vh);
  background: @color-white;;
  padding: 0.7rem 0;
  .sc(0.36rem, #333);
  .success-svg{
    width: 2.2rem !important;
    height: 2.2rem !important;
    margin-bottom: 0.64rem;
  }
  span{
    .sc(0.28rem, #999);
    margin-top: 0.2rem;
  }
  footer{
    width: 100%;
    margin-top: 0.6rem;
  }
}

</style>
