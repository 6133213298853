var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "confirm-success df fdc alc" },
    [
      _c("icon-svg", {
        staticClass: "main-color success-svg",
        attrs: { "icon-class": "yi_icon_xuanze" },
      }),
      _c("div", [_vm._v("确认收货成功")]),
      _c(
        "footer",
        {
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toOrderList.apply(null, arguments)
            },
          },
        },
        [_c("MyButton", { attrs: { title: "好的", is_disabled: false } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }